@use "sass:map";
//$primary: darken(#33cc33, 20%);
$enable-gradients: true;
$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;
$blue:     #0d6efd;
$indigo:   #6610f2;
$purple:   #6f42c1;
$pink:     #d63384;
$red:      #dc3545;
$orange:   #fd7e14;
$yellow:   #ffc107;
$green:    #198754;
$teal:     #20c997;
$cyan:     #0dcaf0;
$primary:  $blue;
$secondary:$gray-600;
$success:  $green;
$info:     $cyan;
$warning:  $yellow;
$danger:   $red;
$light:    $gray-100;
$dark:     $gray-900;
$navy:    #000090;
$aqua:    #00FFFF;
$brBlue:  #0000FF;
$brGreen: #00FF00;
$brRed:   #FF0000;
$brYellow:#FFFF00;
$brPink:  #FF00FF;
$brPurple: #800080;
$brDarkGreen: #008000;
$firdagreen: #3a5757;
$firdateallight: #bccec2;
$firdagreendark: #004b1c;
$firdablue: #95cdea;
$gradient: linear-gradient(180deg, rgba($white, .15), rgba($white, 0));



//$primary: #007bff !default;
//$indigo:  #6610f2 !default;
//$purple:  #6f42c1 !default;
//$pink:    #e83e8c !default;
//$red:     #dc3545 !default;
//$orange:  #fd7e14 !default;
//$green:   #28a745 !default;
//$teal:    #20c997 !default;
//$cyan:    #17a2b8 !default;
//$navy:    #000090 !default;
//$aqua:    #00FFFF !default;
//$black:   #000000 !default;
//$brBlue:  #0000FF;
//$brGreen: #00FF00;
//$brRed:   #FF0000;
//$brYellow:#FFFF00;
//$brPink:  #FF00FF;
//$brPurple: #800080;
//$brDarkGreen: #008000;
//$danger:  #dc3545 !default;
//$dark:    #212529 !default;
//$light:   #f8f9fa !default;
//$info:    #0dcaf0 !default;
//$success: #198754 !default;
//$warning: #ffc107 !default;
//$secondary: #6c757d !default;

$theme-colors: (
        "secondary": $secondary,
        "warning": $warning,
        "success": $success,
        "info": $info,
        "light": $light,
        "dark": $dark,
        "danger": $danger,
        "primary": $primary,
        "indigo": $indigo,
        "purple": $purple,
        "orange": $orange,
        "pink": $pink,
        "navy": $navy,
        "aqua": $aqua,
        "black": $black,
        "brblue": $brBlue,
        "brgreen": $brGreen,
        "brred": $brRed,
        "bryellow": $brYellow,
        "brpink": $brPink,
        "brpurple": $brPurple,
        "brdarkgreen": $brDarkGreen,
        "firdagreen": $firdagreen,
        "firdateallight": $firdateallight,
        "firdagreendark": $firdagreendark,
        "firdablue": $firdablue
);
$custom-colors: (
        "indigo": $indigo,
        "purple": $purple,
        "orange": $orange,
        "pink": $pink,
        "navy": $navy,
        "aqua": $aqua,
        "black": $black,
        "brblue": $brBlue,
        "brgreen": $brGreen,
        "brred": $brRed,
        "bryellow": $brYellow,
        "brpink": $brPink,
        "brpurple": $brPurple,
        "brdarkgreen": $brDarkGreen,
        "firdagreen": $firdagreen,
        "firdateallight": $firdateallight,
        "firdagreendark": $firdagreendark,
        "firdablue": $firdablue
);

$grays: (
        "100": $gray-100,
        "200": $gray-200,
        "300": $gray-300,
        "400": $gray-400,
        "500": $gray-500,
        "600": $gray-600,
        "700": $gray-700,
        "800": $gray-800,
        "900": $gray-900
);

$hexopacity: 44;
$fc-theme-colors:
        indigo,
        purple,
        pink,
        navy,
        aqua,
        black,
        brblue,
        brgreen,
        brred,
        bryellow,
        brpink,
        brpurple,
        orange,
        primary,
        info,
        secondary,
        warning,
        danger,
        dark,
        success,
        brdarkgreen,
        firdagreen,
        firdateallight,
        firdagreendark,
        firdablue;
@mixin gradient-bg($color: null) {
  background-color: $color;

  @if $enable-gradients {
    background-image: var(--#{$variable-prefix}gradient);
  }
}

@import "~bootstrap5/scss/functions";
@import "~bootstrap5/scss/variables";
@import "~bootstrap5/scss/variables-dark";
@import "~bootstrap5/scss/mixins";
@import "~bootstrap5/scss/maps";

@each $state, $value in map-keys($custom-colors) {
  //.alert-#{$state} {
  //  --#{$prefix}alert-color: var(--#{$prefix}#{$state}-text-emphasis);
  //  --#{$prefix}alert-bg: var(--#{$prefix}#{$state}-bg-subtle);
  //  --#{$prefix}alert-border-color: var(--#{$prefix}#{$state}-border-subtle);
  //  --#{$prefix}alert-link-color: var(--#{$prefix}#{$state}-text-emphasis);
  //}
  $color: map-get($custom-colors, $state);
  $bgsubtle: lighten($color, 40%);
  $bordersubtle: lighten($color, 30%);
  $custom-colors-text: ($state: $state);
  $custom-colors-bg-subtle: ($state: $bgsubtle);
  $custom-colors-border-subtle: ($state: $bordersubtle);

  $theme-colors-text: map-merge($theme-colors-text, $custom-colors-text);
  $theme-colors-bg-subtle: map-merge($theme-colors-bg-subtle, $custom-colors-bg-subtle);
  $theme-colors-border-subtle: map-merge($theme-colors-border-subtle, $custom-colors-border-subtle);
}

@import "~bootstrap5/scss/bootstrap";
@import "~bootstrap5/scss/buttons";
@import "~bootstrap5/scss/badge";

@mixin border-opacity($color, $opacity: 0.25) {
  box-shadow: 0 0 0 0.13rem $color;
  box-shadow: 0 0 0 0.13rem rgba($color, $opacity);

}
html, body{
  font-family: 'Ubuntu', verdana;
}
body {
  max-width: 99.99%;
}
body > .container-fluid{
  padding: 0px;
}
.w-20{
  width: 20% !important;
}
#filtrList {
  overflow-y: auto;
}
.h4.border{
  cursor: pointer;
}
.h4.border:hover {
  border-width: 2px !important;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;
}
.filtr-item .h4.border:hover {
  padding-right: 2px !important;
  padding-bottom: 2px !important;
}
body > .card, body > .container-fluid > .card, body > .container-fluid > .card > .card-header.bg-indigo, body > .card > .card-header.bg-indigo{
  border: none;
  border-radius: 0px;
}

@each $fc-theme-color in $fc-theme-colors {
  .border-filter-#{"" + $fc-theme-color} {
    $clr: #{theme-color(#{"" + $fc-theme-color})};
    box-shadow: 0 0 0 0.13rem #{$clr+'44'};
  }
  .border-filter-#{"" + $fc-theme-color}:hover, .border-filter-#{"" + $fc-theme-color}:active {
    $clr: #{theme-color(#{"" + $fc-theme-color})};
    box-shadow: 0 0 0 0.13rem #{$clr};
  }
}

.icon-spacer::before,
.icon-blank::before {
  display: inline-block;
  width: 1em;
  content: ' ';
}

$alert-padding-x: '0rem';
$alert-padding-y: '0rem';
$alert-margin-bottom: 0rem;
$alert-margin-bottom: 0rem;
$alert-color-level: none;
$alert-bg-level: none;

.btn-xs, .btn-group-xs > .btn {
  --bs-btn-padding-y: 0.15rem;
  --bs-btn-padding-x: 0.25rem;
  --bs-btn-font-size: 0.675rem;
  --bs-btn-border-radius: 0.25rem;
}

.popover {
  --bs-popover-max-width: auto !important;
}
